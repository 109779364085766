import React, { useEffect, useState } from "react"
import tw, { styled } from "twin.macro"
import Logo from "@root/Logo"
import { motion } from "framer-motion"
import { navigate, Link } from "gatsby"
import { useWindowSize } from "@hooks/useWindowSize"
import { globalHistory as history } from "@reach/router"
import { useStateContext } from "@context/stateContext"

const MobileNavigationContainer = styled(motion.div)`
  ${tw`absolute inset-0 overflow-hidden text-white transition-all duration-75 ease-linear font-primary`}
  height: 80px;
  background-color: ${({ menuOpen }) => (menuOpen ? "white" : "black")};
  transition: background-color 0.15s ease-in-out;
  // ${({ closing }) => closing && `transition-delay: 0.1s;`} // Delay only during closing
`

const ToggleMenuButton = styled(motion.button)`
  ${tw` focus:outline-none`}
  position: absolute;
  right: 20px;
  top: 30px;
  font-size: 16px;
`

const CloseMenuButton = styled(motion.button)`
  ${tw`pt-4 focus:outline-none`}
  width: 26px;
  height: 26px;
`

const Nav = styled.nav`
  ${tw`absolute flex flex-col items-center justify-between w-full px-5 pt-32 pb-10 md:pt-40`}
  ${({ windowHeight }) => `height: ${windowHeight}px`}
`

const Ul = styled.ul`
  ${tw`z-50 flex flex-col items-start overflow-auto`}
`

const Li = styled(motion.li)`
  ${tw`inline-flex flex-col text-[40px] font-black leading-tight text-left text-black uppercase cursor-pointer relative w-full mb-2`}
  &::after {
    content: "";
    ${tw`transition duration-500 ease-in-out`}
  }
  &.menu-active {
    ${tw`relative`}
  }
  &.first-level-item {
    ${tw`mb-4`}

    a.first-level-item-headline {
      ${tw`mb-6`}
    }
  }
  &.active {
    &::after {
      content: "";
      ${tw`absolute top-[50px] left-0 right-0 h-[3px] bg-yellow`}
    }
  }
`

const NestedMobileNavWrapper = styled(motion.div)`
  ${tw`flex flex-col items-start font-primary`}
`

const SecondLevelMobileNav = styled(motion.ul)`
  ${tw`flex flex-col items-start w-full text-base text-black`}

  li {
    ${tw`text-[24px] flex-col relative w-full pr-5`}

    a {
      ${tw`pr-5 my-3 no-underline `}
    }

    span {
      ${tw`absolute right-0 font-light text-7xl -top-3`}
    }

    &::after {
      ${tw`hidden`}
    }
  }
`

const ThirdLevelMobileNav = styled(motion.ul)`
  ${tw`flex flex-col items-start text-[19px] text-black font-semibold py-2`}
  font-weight: 700;
`

const Button = styled(motion.button)`
  ${tw`inline-flex flex-col text-[40px] font-black leading-tight text-left text-black uppercase cursor-pointer relative w-full mb-2`}
  &::after {
    content: "";
    ${tw`transition duration-500 ease-in-out`}
  }
  &.menu-active {
    ${tw`relative`}

    &::after {
      ${tw`absolute bottom-0 left-0 right-0 h-px bg-current`}
    }
  }
`

const variantsContainer = {
  menuOpen: ({ height }) => ({
    height: height,
    transition: {
      // type: "spring",
      type: "tween",
      velocity: 10,
      duration: 0.2,
      when: "beforeChildren",
      staggerChildren: 0.1,
      delayChildren: -0.2,
    },
  }),
  menuClosed: {
    height: 80,
    transition: {
      type: "tween",
      velocity: 10,
      duration: 0.2,
    },
  },
}

const variantsListItem = {
  menuOpen: {
    opacity: 1,
    transition: {
      duration: 0.5,
      stagger: 0.07,
    },
  },
  menuClosed: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
}

//* ---------------------------
//* Component MobileNavigation
//* ---------------------------

function MobileNavigation({ menuItems, isMobile }) {
  const stateContext = useStateContext()
  const [menuOpen, setMenuOpen] = useState(false)
  const windowSize = useWindowSize()
  const { location } = history

  if (menuOpen) {
    document.body.classList.add("overflow-hidden")
    document.body.classList.remove("overflow-auto")
    document.body.style.touchAction = "none"
  } else {
    document.body.classList.remove("overflow-hidden")
    document.body.classList.add("overflow-auto")
    document.body.style.touchAction = "auto"
  }

  const [firstLevelOpen, setFirstLevelOpen] = useState({})
  const [secondLevelOpen, setSecondLevelOpen] = useState({})

  const [closing, setClosing] = useState(false)

  const handleMenuClose = () => {
    setClosing(true)
    setTimeout(() => {
      setMenuOpen(false)
      setClosing(false)
    }, 200)
  }

  const navigateToRoute = path => {
    handleMenuClose()
    setTimeout(() => {
      navigate(path)
    }, 200)
  }

  const handleFirstLevelClick = id => {
    setFirstLevelOpen(prev => ({ ...prev, [id]: !prev[id] }))
  }

  const handleSecondLevelClick = (firstLevelId, secondLevelId) => {
    setSecondLevelOpen(prev => ({
      ...prev,
      [firstLevelId]: {
        ...prev[firstLevelId],
        [secondLevelId]: !prev[firstLevelId]?.[secondLevelId],
      },
    }))
  }

  const handleThirdLevelClick = path => {
    navigateToRoute(path)
  }

  useEffect(() => {
    document.body.style.overflow = menuOpen ? "hidden" : "unset"
  }, [menuOpen])

  const contactPopUpOpen = () => {
    stateContext.dispatch({ action: "SET_CONTACT_POP_UP", data: true })
    setMenuOpen(false)
    document.body.style.overflow = "hidden"
  }

  return (
    <MobileNavigationContainer
      custom={{ height: windowSize.height }}
      initial="menuClosed"
      animate={menuOpen ? "menuOpen" : "menuClosed"}
      variants={variantsContainer}
      menuOpen={menuOpen}
      closing={closing}
    >
      <div onClick={() => navigateToRoute("/")}>
        <Logo enlarged={menuOpen} isDark={!menuOpen} isMobile={true} />
      </div>
      <Nav windowHeight={windowSize.height}>
        <Ul className="w-full max-w-[440px]">
          {menuItems.map(item => {
            const isFirstLevelActive = firstLevelOpen[item.id]
            const isCurrentPath =
              location.pathname === item.path ||
              item.childItems.nodes.some(childItem => location.pathname === childItem.path || (childItem.childItems && childItem.childItems.nodes.some(grandchild => location.pathname === grandchild.path)))

            return (
              <Li key={item.id} className={`first-level-item ${isFirstLevelActive ? "menu-active" : ""} ${isCurrentPath ? "active" : ""}`} onClick={() => handleFirstLevelClick(item.id)}>
                {item.label === "Kontakt" ? (
                  <Button onClick={contactPopUpOpen}>{item.label}</Button>
                ) : (
                  <Link
                    to={item.path}
                    className="first-level-item-headline"
                    onClick={e => {
                      e.stopPropagation()
                      navigateToRoute(item.path)
                      setMenuOpen(false)
                    }}
                  >
                    {item.label}
                  </Link>
                )}

                {item.childItems && item.childItems.nodes.length > 0 && <motion.span css={tw`text-7xl font-light flex justify-self-end absolute right-0 top-[-10px]`}>{isFirstLevelActive ? "-" : "+"}</motion.span>}
                {isFirstLevelActive && item.childItems && item.childItems.nodes.length > 0 && (
                  <NestedMobileNavWrapper>
                    <SecondLevelMobileNav>
                      {item.childItems.nodes.map(childItem => {
                        const isSecondLevelActive = secondLevelOpen[item.id]?.[childItem.id]
                        const isChildCurrentPath = location.pathname === childItem.path || childItem.childItems.nodes.some(grandchild => location.pathname === grandchild.path)

                        return (
                          <Li key={childItem.id} className={`second-level-item ${isSecondLevelActive ? "menu-active" : ""} ${isChildCurrentPath ? "active" : ""}`}>
                            <Link
                              to={childItem.path}
                              onClick={e => {
                                e.stopPropagation()
                                navigateToRoute(childItem.path)
                                setMenuOpen(false)
                              }}
                            >
                              {childItem.label}
                            </Link>
                            {childItem.childItems && childItem.childItems.nodes.length > 0 && (
                              <span
                                css={tw`flex text-3xl font-light transition duration-500 ease-in-out justify-self-end`}
                                onClick={e => {
                                  e.stopPropagation()
                                  handleSecondLevelClick(item.id, childItem.id)
                                }}
                              >
                                {isSecondLevelActive ? "-" : "+"}
                              </span>
                            )}
                            {isSecondLevelActive && childItem.childItems && childItem.childItems.nodes.length > 0 && (
                              <ThirdLevelMobileNav>
                                {childItem.childItems.nodes.map(thirdItem => (
                                  <div key={thirdItem.id} css={tw`!mb-2`}>
                                    <Link
                                      to={thirdItem.path}
                                      onClick={e => {
                                        e.stopPropagation()
                                        handleThirdLevelClick(thirdItem.path)
                                        setMenuOpen(false)
                                      }}
                                    >
                                      {thirdItem.label}
                                    </Link>
                                  </div>
                                ))}
                              </ThirdLevelMobileNav>
                            )}
                          </Li>
                        )
                      })}
                    </SecondLevelMobileNav>
                  </NestedMobileNavWrapper>
                )}
              </Li>
            )
          })}
        </Ul>
        <CloseMenuButton
          onClick={handleMenuClose}
          variants={variantsListItem}
        >
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L24 24" stroke="#E6FF00" strokeWidth="4" />
            <path d="M24 2L2 24" stroke="#E6FF00" strokeWidth="4" />
          </svg>
        </CloseMenuButton>
      </Nav>
      <ToggleMenuButton onClick={() => setMenuOpen(true)} animate={{ y: menuOpen ? 40 : 0, opacity: menuOpen ? 0 : 1 }}>
        Menu
      </ToggleMenuButton>
    </MobileNavigationContainer>
  )
}

export default MobileNavigation
